import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import { IHandleNotification, INotifications } from 'utils/models';

// graphql
import query from 'graphql/query';
import mutation from 'graphql/mutation';

// components
import NotificationMessages from 'components/notifications/NotificationMessages';

interface IStatusWithDoctorNotification {
  code: string;
  success: boolean;
  message: string;
  notifications?: INotifications[];
}

type TNotifications = {
  setHasUnreadNotifications: (param: boolean) => void;
};

const Notifications: FC<TNotifications> = ({ setHasUnreadNotifications }) => {
  const mounted = useRef(false);

  const [doctorNotifications, { data, loading }] = useLazyQuery<{
    doctorNotifications: IStatusWithDoctorNotification;
  }>(query.doctorNotifications, {
    fetchPolicy: 'network-only'
  });

  const [doctorReadNotification] = useMutation<{
    doctorReadNotification: IStatusWithDoctorNotification;
  }>(mutation.doctorReadNotification, {
    fetchPolicy: 'network-only'
  });

  const notifications = data?.doctorNotifications?.notifications;
  const notificationsWithDefault = useMemo(() => notifications || [], [notifications]);

  const handleDebounceFetchDoctorNotifications = useDebouncedCallback(() => {
    doctorNotifications();
  }, 500);

  // debounce doctorNotifications api call while component is mounting
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      handleDebounceFetchDoctorNotifications();
    }
    return () => {
      mounted.current = false;
    };
  }, [doctorNotifications, handleDebounceFetchDoctorNotifications]);

  useEffect(() => {
    if (notificationsWithDefault.length > 0) {
      const unReadNotification = notificationsWithDefault.find(({ is_read }) => !is_read);
      setHasUnreadNotifications((unReadNotification && 'is_read' in unReadNotification) || false);
    }
  }, [notificationsWithDefault, setHasUnreadNotifications]);

  const handleReadNotification = async ({ is_read, id, link }: IHandleNotification) => {
    if (!is_read) {
      // mutation doctorReadNotification to change is_read status
      await doctorReadNotification({
        variables: {
          id
        }
      });
    }

    window.location.href = link;
  };

  return (
    <NotificationMessages
      notifications={notificationsWithDefault}
      loading={loading}
      handleReadNotification={handleReadNotification}
    />
  );
};

export default Notifications;
