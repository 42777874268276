import { AuthProvider } from 'contexts/AuthContext';
import React, { FC } from 'react';
// import Router from './routes';
import Router from './Router';

const App: FC = () => (
  <AuthProvider>
    <Router />
  </AuthProvider>
);

export default App;
