import React, { FC, useEffect, Fragment, ReactNode } from 'react';
import clsx from 'clsx';
import { BookmarkIcon } from '@heroicons/react/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { IProductDetails } from 'utils/models';
import storage from 'utils/storage';

// gql
import { useLazyQuery } from '@apollo/client';
import query from 'graphql/query';

// components
import { SvgCapsule, SvgFlower, SvgOil, SvgStar, SvgTopical, SvgVape } from 'components/SvgIcons';
import EScriptProductImage from './EScriptProductImage';
import { TLimits } from 'utils/types';
import { NumericFormat } from 'react-number-format';

interface IProductDetailId {
  itemId: string;
  bookmarkedProducts: string[];
  setBookmarkedProducts: (products: string[]) => void;
}

const limits: {
  [key in TLimits]: {
    svg: ReactNode;
  };
} = {
  FLOWER: {
    svg: <SvgFlower className="w-10 h-10 mx-6 text-white" />
  },
  OIL: {
    svg: <SvgOil className="w-10 h-10 mx-6 text-white" />
  },
  ORAL: {
    svg: <SvgCapsule className="w-10 h-10 mx-6 text-white" />
  },
  VAPE: {
    svg: <SvgVape className="w-10 h-10 mx-6 text-white" />
  },
  TOPICAL: {
    svg: <SvgTopical className="w-10 h-10 mx-6 text-white" />
  }
};

const ProductDetails: FC<IProductDetailId> = ({
  itemId,
  bookmarkedProducts,
  setBookmarkedProducts
}) => {
  const [getProductDetails, { data, loading }] = useLazyQuery<{
    productDetails: {
      code: string;
      success: boolean;
      product: IProductDetails;
      message: string;
    };
  }>(query.productDetails, { fetchPolicy: 'network-only' });
  const product = data?.productDetails?.product;

  useEffect(() => {
    getProductDetails({
      variables: {
        id: itemId
      }
    });
  }, [getProductDetails, itemId]);

  const handleSaveBookmarkedIds = () => {
    const currentIds = [...bookmarkedProducts];

    if (!currentIds.includes(itemId)) {
      currentIds.push(itemId);
      setBookmarkedProducts(currentIds);
      storage.setItem('bookmarked-ids', JSON.stringify(currentIds));
    } else {
      const filteredItems = currentIds.filter((item) => item !== itemId);
      setBookmarkedProducts(filteredItems);
      storage.setItem('bookmarked-ids', JSON.stringify(filteredItems));
    }
  };

  if (loading) return <>Loading...</>;

  return (
    <Fragment>
      <div className="bg-polln-solid-green-600 flex justify-between rounded-t-xl">
        <div className="flex items-center py-4">
          {product && <span>{limits[product.product_type.icon].svg}</span>}
          <div>
            <p className="text-white">Product Information Sheet</p>
            <h2 className="text-white text-xl font-bold">{product?.product_name}</h2>
          </div>
        </div>
        <div className="flex items-center justify-end py-4 mx-6">
          <button type="button" onClick={handleSaveBookmarkedIds}>
            <BookmarkIcon
              className={clsx(
                `w-10 h-10`,
                bookmarkedProducts.includes(itemId) ? 'text-polln-orange-100' : `text-white`
              )}
            />
          </button>
        </div>
      </div>
      <div className="border border-gray-200 border-solid shadow grid grid-cols-6">
        <div className="col-span-6 md:col-span-3 py-4 md:order-1 order-2">
          <div className="pl-4 font-bold">{product?.status || '-'}</div>
          <div className="bg-polln-light-green-600 sm:max-w-xs w-full rounded-r-lg my-4">
            <div className="py-4 px-6">
              <div className="text-white">
                <p className="capitalize">
                  <span className="font-bold">Brand</span>: {product?.brand || '-'}
                </p>
                <p className="capitalize">
                  <span className="font-bold">Size</span>: {product?.size || '-'}
                </p>
                <p className="capitalize">
                  <span className="font-bold">Type</span>:{' '}
                  {product?.product_type.name.toLowerCase() || '-'}
                </p>
                <p className="capitalize">
                  <span className="font-bold">Format</span>: {product?.format || '-'}
                </p>
                <p className="capitalize">
                  <span className="font-bold">Strength</span>: {product?.strength || '-'}
                </p>
                <p className="capitalize">
                  <span className="font-bold">Administration</span>:{' '}
                  {product?.administration || '-'}
                </p>
                <p className="capitalize">
                  <span className="font-bold">Price</span>:{' '}
                  <NumericFormat
                    value={product?.price || 0}
                    prefix="$"
                    decimalScale={2}
                    thousandSeparator
                    displayType="text"
                  />
                </p>

                <div className="flex-wrap inline-flex gap-2 mt-2">
                  <div className="bg-white rounded-2xl inline-block">
                    <p className="text-polln-light-green-800 py-1 px-3 text-sm font-bold">
                      Category: {product?.category}
                    </p>
                  </div>
                  <div className="bg-white rounded-2xl inline-block">
                    <p className="text-polln-light-green-800 py-1 px-3 text-sm font-bold">
                      {`${
                        !product?.schedule.toLowerCase().includes('schedule') ? 'Schedule:' : ''
                      } ${product?.schedule}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-4 flex items-center">
            {product?.stock && product?.stock > 0 ? (
              <CheckCircleIcon className="w-10 h-10 text-polln-light-green-800" />
            ) : (
              <XCircleIcon className="w-10 h-10 text-red-800" />
            )}
            <div className="ml-2">
              <p className="text-polln-light-green-800 font-bold uppercase">
                {(product?.stock || 0) > 0 ? 'In stock' : 'Out of stock'}
              </p>
              <p className="text-polln-light-green-800 font-bold text-sm">
                {product?.estimate_dispatch || 'Not set'}
              </p>
            </div>
          </div>
          {product?.is_formularly && (
            <div className="ml-4 flex my-4 items-center">
              <SvgStar className="w-10 h-10" />
              <h3 className="font-bold ml-2 text-polln-orange-100">Formulary</h3>

              {/**
               * DONT REMOVE THIS COMMENT
               */}
              {/* <div className="group flex relative">
              <button>
                <QuestionMarkCircleIcon className="w-5 h-5 text-polln-light-gray-100 ml-2 mt-1" />
              </button>
              <div
                className={clsx(
                  'group-hover:opacity-100 group-hover:visible',
                  'transition-opacity   opacity-0',
                  'translate-y-full -translate-x-28 sm:translate-x-3',
                  'absolute -top-96 sm:-top-72 left-1/2',
                  'flex justify-end',
                  'break-all invisible'
                )}
              >
                <div className="w-56 h-44 p-4 ml-5 shadow-lg ring-1 ring-black bg-white ring-opacity-5 rounded-md overflow-y-auto text-black text-sm"></div>
              </div>
            </div> */}
            </div>
          )}
        </div>
        <div className="col-span-6 md:col-span-3 md:order-2 order-1 p-4">
          <EScriptProductImage
            image={product?.product_image ?? null}
            className="shadow-lg ring-1 ring-black  bg-white ring-opacity-5 h-full w-full"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ProductDetails;
