import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

const GuestLayout: FC = () => {
  return (
    <div className="h-screen bg-polln-white-400">
      <Outlet />
    </div>
  );
};

export default GuestLayout;
