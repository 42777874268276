import React, { FC, Fragment, useState, useRef, ReactNode } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Portal } from 'react-portal';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core/index';

interface IPopOver {
  children: ReactNode | ReactNode[];
  placement?: Placement;
  offSetX: number;
  offSetY: number;
  classWidth: string;
  btnToggle: ReactNode | ReactNode[];
}

const PopOver: FC<IPopOver> = ({
  children,
  placement,
  offSetX,
  offSetY,
  classWidth,
  btnToggle
}) => {
  const popperElRef = useRef(null);
  const [referenceElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: { offset: [offSetX, offSetY] }
      }
    ]
  });

  return (
    <Popover as="div" className="relative">
      {({ open }) => (
        <>
          {/** @ts-ignore */}
          <Popover.Button as="div" ref={setTargetElement}>
            {btnToggle}
          </Popover.Button>
          <Portal>
            <div
              ref={popperElRef}
              style={{ ...styles.popper, zIndex: 99 }}
              {...attributes.popper}
              className={classWidth}
            >
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
                beforeEnter={() => {
                  setPopperElement(popperElRef.current);
                }}
                afterLeave={() => {
                  setPopperElement(null);
                }}
              >
                <Popover.Panel className="absolute left-0 top-2 z-10 mt-3 w-full">
                  <div className="overflow-hidden rounded-lg bg-white p-5 break-all shadow">
                    {children}
                  </div>
                </Popover.Panel>
              </Transition>
            </div>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export default PopOver;
