import { USER_LOGIN, UPDATE_GP, USER_ME, USER_LOGOUT, USER_ONBOARD } from 'store/types';
import { IUser } from 'utils/models';

export interface IActionPayload {
  type: typeof USER_LOGIN | typeof USER_LOGOUT | typeof UPDATE_GP | typeof USER_ME |  typeof USER_ONBOARD;
  payload?: any;
}

const userMe = (payload: IUser) => {
  const action: IActionPayload = {
    type: USER_ME,
    payload
  };

  return action;
};

const userLogin = () => {
  const action: IActionPayload = {
    type: USER_LOGIN
  };

  return action;
};

const userLogout = () => {
  const action: IActionPayload = {
    type: USER_LOGOUT
  };

  return action;
};

const updateGp = () => {
  const action: IActionPayload = {
    type: UPDATE_GP
  };

  return action;
};


const userOnBoard = (isOnBoarding: boolean) => {
  const action: IActionPayload = {
    type: USER_ONBOARD,
    payload: isOnBoarding
  };

  return action;
};

const actions = {
  userLogin,
  userLogout,
  updateGp,
  userMe,
  userOnBoard
};

export default actions;
