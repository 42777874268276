import React, { FC } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { IDoctorNotifications, IHandleNotification, INotifications } from 'utils/models';
import { groupBy } from 'utils/helpers';

// components
import Button from 'components/Button';
import LoaderSpinner from 'components/LoaderSpinner';

interface INotifData extends IDoctorNotifications {
  loading?: boolean;
  handleReadNotification: ({ ...props }: IHandleNotification) => Promise<any> | void;
}

const NotificationMessages: FC<INotifData> = ({
  notifications,
  loading,
  handleReadNotification
}) => {
  const notificationsGroupByDate = groupBy(notifications || [], (notifications: INotifications) => {
    const { created_at } = notifications;
    const dates = format(new Date(created_at), 'eeee d MMMM uuuu');
    return dates;
  });

  if (loading)
    return (
      <div className="flex justify-center items-center my-2">
        <LoaderSpinner className="w-5 h-5" />
      </div>
    );

  return (
    <div className="hidden md:block max-h-[calc(100vh-12em)] overflow-y-auto">
      {Object.keys(notificationsGroupByDate).length > 0 &&
        Object.keys(notificationsGroupByDate).map((date, index: number) => (
          <div key={index} className="mt-4">
            <p className="text-gray-900 mt-8 text-sm text-opacity-30 font-bold">
              {date && format(new Date(date), 'eeee d MMMM uuuu')}
            </p>

            {notificationsGroupByDate[date].map((item: INotifications) => (
              <Button
                key={item.id}
                variant="invisible"
                className="text-left"
                onClick={() =>
                  handleReadNotification({ id: item.id, is_read: item.is_read, link: item.link })
                }
              >
                <div className="-ml-5 grid grid-flow-col gap-3 items-center pt-2.5 px-4 border-t border-polln-light-green-700">
                  <div
                    className={clsx(
                      'w-2.5 h-2.5 rounded-full -ml-4 mr-4',
                      item.is_read ? 'bg-transparent' : 'bg-polln-orange-100'
                    )}
                  />
                  <div>
                    <h3 className="text-base font-extrabold">{item.title}</h3>
                    <p className="font-regular text-sm leading-5 pr-5 break-normal">
                      {item.content}
                    </p>
                  </div>
                  <p className="text-gray-900 text-opacity-30 font-regular text-sm">
                    {item.created_at && format(new Date(item.created_at), "h:mmaaaaa'm'")}
                  </p>
                </div>
              </Button>
            ))}
          </div>
        ))}
    </div>
  );
};

export default NotificationMessages;
