import { Callback } from 'utils/types';
import EventEmitter from 'events';
import { isMobile } from 'utils/helpers';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import Video, { ConnectOptions, LocalTrack, Room, Logger } from 'twilio-video';
import { useCallback, useEffect, useRef, useState } from 'react';
import { IS_DEV } from 'utils/constants';
import { CONF_ENVIRONMENT } from 'utils/config';

const logger = Logger.getLogger('twilio-video');
logger.setLevel('debug');

// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(
  localTracks: LocalTrack[],
  onError: Callback,
  options?: ConnectOptions
) {
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const [isConnecting, setIsConnecting] = useState(false);
  const localTracksRef = useRef<LocalTrack[]>([]);
  const optionsRef = useRef(options);

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
  }, [localTracks]);

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  const connect = useCallback(
    (token) => {
      setIsConnecting(true);
      return Video.connect(token, { ...optionsRef.current, tracks: [] }).then(
        (newRoom) => {
          if (IS_DEV.includes(CONF_ENVIRONMENT || 'development')) {
            VideoRoomMonitor.registerVideoRoom(newRoom);
            VideoRoomMonitor.openMonitor();
          }

          setRoom(newRoom);
          const disconnect = () => newRoom.disconnect();

          if (window.location.href.match(/\/patient\//g)) {
            newRoom.on('participantDisconnected', disconnect);
          }

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(new EventEmitter() as Room));
            window.removeEventListener('beforeunload', disconnect);
            if (window.location.href.match(/\/patient\//g)) {
              window.location.href = '/patient/dashboard';
            }
            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          localTracksRef.current.forEach((track) =>
            // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
            // However, tracks must be published manually in order to set the priority on them.
            // All video tracks are published with 'low' priority. This works because the video
            // track that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            newRoom.localParticipant.publishTrack(track, {
              priority: track.kind === 'video' ? 'low' : 'standard'
            })
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }
        },
        (error) => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [onError]
  );

  return { room, isConnecting, connect };
}
