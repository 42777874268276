import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { IMeResponse, ITokenResponse, IUser } from 'utils/models';
import storage from 'utils/storage';

// redux
import { useDispatch } from 'react-redux';
import { actions } from 'store/actions';

// graphql
import { useLazyQuery, useMutation } from '@apollo/client';
import query from 'graphql/query';
import mutation from 'graphql/mutation';

// components
import { toast } from 'components/Toast';

interface IContext {
  user: IUser | null;
  isLoggedIn: boolean;
  isOnBoarding: boolean;
  isLoading: boolean;
  token: string | null;
  isAdmin: boolean;
  login: (email: string, password: string) => Promise<any> | void;
  loginOtp: (code: string, hash: string, doctorId: string) => Promise<any> | void;
  logout: () => void;
  signup: (
    email: string,
    password: string,
    first_name: string,
    last_name: string
  ) => Promise<any> | void;
  getMe: () => Promise<any> | void;
  logMe: () => Promise<any> | void;
}

const AuthContext = createContext<IContext>({
  user: null,
  isLoggedIn: false,
  isOnBoarding: false,
  isLoading: true,
  token: null,
  isAdmin: false,
  login: (email: string, password: string) => {},
  loginOtp: (code: string, hash: string, doctorId: string) => {},
  logout: () => {},
  signup: (email: string, password: string) => {},
  getMe: () => {},
  logMe: () => {}
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [tokenRefresh, setTokenRefresh] = useState<string | null>(null);
  const [expiry, setExpire] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isOnBoarding, setIsOnBoarding] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(true);

  const dispatch = useDispatch();

  /**
   * doctor login
   */
  const [doctorLogin] = useMutation<{
    doctorLogin: ITokenResponse;
  }>(mutation.doctorLogin, {
    fetchPolicy: 'network-only'
  });

  /**
   * doctor login otp
   */
  const [doctorLogInOtp] = useMutation<{
    doctorLogInOtp: ITokenResponse;
  }>(mutation.doctorLogInOtp, {
    fetchPolicy: 'network-only'
  });

  /**
   * doctor sign up
   */
  const [doctorSignUp] = useMutation<{
    doctorSignUp: ITokenResponse;
  }>(mutation.doctorSignUp, {
    fetchPolicy: 'network-only'
  });

  /**
   * me query
   */
  const [doctorMe, { data: dataMe, loading }] = useLazyQuery<{
    doctorMe: IMeResponse;
  }>(query.doctorMe, {
    fetchPolicy: 'network-only'
  });

  const handleAuth = useCallback(
    (
      meToken: string | null,
      me: IUser,
      login: boolean,
      status: boolean,
      meTokenExpiration: string | null,
      meTokenRefresh: string | null,
      meAdmin: boolean
    ) => {
      if (meToken && meTokenExpiration && meTokenRefresh) {
        storage.setItem('token', meToken);
        storage.setItem('token-refresh', meTokenRefresh);
        storage.setItem('expiry', meTokenExpiration);
        setToken(meToken);
        setTokenRefresh(meTokenRefresh);
        setExpire(meTokenExpiration);
        setUser(me);
        setIsLoggedIn(login);
        setIsAdmin(meAdmin);
        setIsOnBoarding(status);
        dispatch(actions.userMe(me));
        dispatch(actions.userOnBoard(status));
        dispatch(actions.userLogin());
      }
    },
    [dispatch]
  );

  /**
   * login function
   * @param email
   * @param password
   * @returns
   */
  const login = async (email: string, password: string) => {
    const { data } = await doctorLogin({
      variables: {
        input: {
          email,
          password
        }
      }
    });

    if (data?.doctorLogin?.code === '200' && data?.doctorLogin?.success) {
      if (data.doctorLogin.otpHash === null) {
        const me = data.doctorLogin.doctor;
        const status = data.doctorLogin.doctor.status === 'INACTIVE';
        const token = data.doctorLogin.token;
        const tokenRefresh = data.doctorLogin.refresh_token;
        const expiry = data.doctorLogin.token_expires;
        handleAuth(token, me, true, status, expiry, tokenRefresh, false);
      }
    }

    return data?.doctorLogin;
  };

  /**
   * login otp function
   * @param code
   * @param hash
   * @param doctorId
   * @returns
   */
  const loginOtp = async (code: string, hash: string, doctorId: string) => {
    const { data } = await doctorLogInOtp({
      variables: {
        input: {
          code: +code,
          hash,
          doctor_id: doctorId
        }
      }
    });

    if (data?.doctorLogInOtp.code === '200' && data.doctorLogInOtp.success) {
      const me = data.doctorLogInOtp.doctor;
      const token = data.doctorLogInOtp.token;
      const tokenRefresh = data.doctorLogInOtp.refresh_token;
      const expiry = data.doctorLogInOtp.token_expires;
      handleAuth(token, me, true, false, expiry, tokenRefresh, false);
    }

    return data?.doctorLogInOtp;
  };

  /**
   * signup function
   * @param email
   * @param password
   * @param first_name
   * @param last_name
   * @returns
   */
  const signup = async (email: string, password: string, first_name: string, last_name: string) => {
    const { data } = await doctorSignUp({
      variables: {
        input: {
          email,
          password,
          first_name,
          last_name
        }
      }
    });

    if (data?.doctorSignUp?.code === '200' && data?.doctorSignUp?.success) {
      const me = data.doctorSignUp.doctor;
      const status = data?.doctorSignUp.doctor.status === 'INACTIVE';
      const token = data.doctorSignUp.token;
      const tokenRefresh = data.doctorSignUp.refresh_token;
      const expiry = data.doctorSignUp.token_expires;
      handleAuth(token, me, true, status, expiry, tokenRefresh, false);
    }

    return data?.doctorSignUp;
  };

  const logout = useCallback(() => {
    storage.removeItem('token');
    storage.removeItem('expiry');
    storage.removeItem('token-refresh');
    dispatch(actions.userLogout());
    setUser(null);
    setIsLoggedIn(false);
    setIsOnBoarding(false);
    setToken(null);
    setExpire(null);
    setTokenRefresh(null);
  }, [dispatch]);

  const getMe = useCallback(async () => {
    try {
      await doctorMe();
    } catch (error) {
      toast({
        title: 'Error Context',
        variant: 'error'
      });
      // logout();
    }
  }, [doctorMe]);

  const logMe = useCallback(() => {
    if (storage.getItem('token')) setToken(storage.getItem('token'));
    if (storage.getItem('expiry')) setExpire(storage.getItem('expiry'));
    if (storage.getItem('token-refresh')) setTokenRefresh(storage.getItem('token-refresh'));

    getMe();
  }, [getMe]);

  useEffect(() => {
    if (dataMe && dataMe?.doctorMe?.code === '200' && dataMe?.doctorMe?.success) {
      const me = dataMe.doctorMe.doctor;
      const status = dataMe.doctorMe.doctor.status === 'INACTIVE';
      const meAdmin = dataMe.doctorMe.is_from_admin;
      handleAuth(token, me, true, status, expiry, tokenRefresh, meAdmin);
    }

    if (
      dataMe &&
      (dataMe?.doctorMe?.code === '422' || dataMe?.doctorMe.code === '404') &&
      !dataMe?.doctorMe?.success
    ) {
      logout();
    }
  }, [dataMe, token, handleAuth, logout, expiry, tokenRefresh]);

  useEffect(() => {
    if (token) getMe();
  }, [getMe, token]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (storage.getItem('token')) setToken(storage.getItem('token'));
    if (storage.getItem('expiry')) setExpire(storage.getItem('expiry'));
    if (storage.getItem('token-refresh')) setTokenRefresh(storage.getItem('token-refresh'));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        loginOtp,
        logout,
        signup,
        getMe,
        logMe,
        user,
        isLoggedIn,
        isOnBoarding,
        isLoading,
        token,
        isAdmin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
