import React, { ReactNode } from 'react';
import { isPast } from 'date-fns';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { CONF_API_URL } from 'utils/config';
import storage from 'utils/storage';
import { api } from 'utils/api';
import { toast } from 'components/Toast';

interface IAppProvider {
  children: ReactNode;
}

const AppProvider = ({ children }: IAppProvider) => {
  const httpLink = createHttpLink({
    uri: `${CONF_API_URL}/graphql`
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = storage.getItem('token');
    const expiry = storage.getItem('expiry');
    const refresh = storage.getItem('token-refresh');

    const newHeaders = {
      ...headers,
      authorization: token
    };

    // console.log(expiry && new Date(+expiry));

    if (expiry && isPast(new Date(+expiry))) {
      try {
        const { data } = await api.get(`${CONF_API_URL}/refresh-token?refreshToken=${refresh}`);
        storage.setItem('token', data.token);
        storage.setItem('expiry', data.exp);
        newHeaders.authorization = data.token;
      } catch (error) {
        console.log(error);
        toast({
          title: 'Apollo Error',
          variant: 'error'
        });
        // window.location.reload();
      }
    } else {
      newHeaders.authorization = token;
    }

    return {
      headers: newHeaders
    };
  });

  const errLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

        toast({
          title: 'Error',
          message: `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          variant: 'error'
        });
        // storage.removeToken('token');
        // if (extensions && extensions.code === 'UNAUTHENTICATED') {
        //   // stores.logout();
        //   store.getState().logout();
        // }
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(errLink).concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false
    }),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      }
    }
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AppProvider;
