import { UPDATE_GP } from 'store/types';

export type TOtherReducerState = {
  hasGp: boolean;
};

const initialState: TOtherReducerState = {
  hasGp: true
};

const reducers = (state: TOtherReducerState = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_GP:
      return {
        ...state,
        hasGp: false
      };
    default:
      return state;
  }
};

export default reducers;
