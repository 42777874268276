import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { IMenuTypes } from 'utils/models';
import useAuth from 'hooks/useAuth';
import storage from 'utils/storage';

// components
import SideActions from './SideActions';
import {
  SvgLefRightArrow,
  SvgOpenBook,
  SvgSideDashboard,
  SvgSideLogout,
  SvgSidePatients,
  SvgSideShedule
} from './SvgIcons';
import Button from './Button';
import Modal from './Modal';
import ModalProductInfo from './modals/ModalProductInfo';

const SideBar: FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [isCollapse, setIsCollapse] = useState<boolean>(true);
  const [openProductInfo, setOpenProductInfo] = useState<boolean>(false);

  const handleCollapse = () => {
    const collapse = !isCollapse;
    setIsCollapse(collapse);
    storage.setItem('collapse', collapse ? 'true' : 'false');
  };

  const links: IMenuTypes[] = [
    {
      icon: <SvgSideDashboard className="w-5 h-5" />,
      label: 'Overview',
      to: '/dashboard'
    },
    {
      icon: <SvgSideShedule className="w-5 h-5" />,
      label: 'Schedule',
      to: '/schedule'
    },
    {
      icon: <SvgSidePatients className="w-5 h-5" />,
      label: 'Patients',
      to: '/patient-list'
    },
    {
      icon: <SvgOpenBook className="w-5 h-5" />,
      label: 'Product Info',
      setting: true,
      onClick: () => setOpenProductInfo(true)
    },
    {
      icon: <SvgSideLogout className="w-5 h-5" />,
      label: 'Log out',
      setting: true,
      onClick: () => {
        logout();
        navigate('/login', { replace: true });
      }
    }
  ];

  useEffect(() => {
    const collapse = storage.getItem('collapse');
    if (collapse) setIsCollapse(JSON.parse(collapse));
  }, []);

  return (
    <>
      <div className="hidden md:block h-full">
        <div className={clsx('bg-polln-light-green-500 h-full relative', !isCollapse && 'w-52')}>
          <div className="absolute top-2.5 -right-3.5">
            <Button onClick={() => handleCollapse()} size="inline" variant="invisible">
              <div className="bg-polln-solid-green-600 rounded-full p-1">
                <SvgLefRightArrow className="text-white w-4 h-4" />
              </div>
            </Button>
          </div>

          <div className="pt-5">
            <SideActions actions={links} isCollapse={isCollapse} />
          </div>
        </div>
      </div>

      <Modal
        open={openProductInfo}
        onClose={setOpenProductInfo}
        size="7xl"
        classNameChild="!p-0"
        round="lg"
      >
        <ModalProductInfo showRecentView showSavedProducts />
      </Modal>
    </>
  );
};

export default SideBar;
